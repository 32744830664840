<template>
  <div
    class="page-table projeTanim mainDiv mb-30"
    id="affix-container"
    style="overflow: hidden !important"
  >
    <div class="animated fadeInUp baslikBg">
      {{ projeForm.projeBaslik }}
      <div class="tanimlamaButon">
        <el-tooltip
          :content="$t('src.views.apps.genel.refresh')"
          :open-delay="500"
          placement="top"
        >
          <el-button
            v-on:click="refreshPage()"
            size="mini"
            type="text"
            icon="el-icon-refresh"
            class="mr-5"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div
      class="page-header card-base card-shadow--medium animated fadeInUp"
      v-loading="loading || stateLoading"
      :element-loading-text="
        loading
          ? $t('src.views.apps.sozlesme.liste.loading')
          : $t('src.views.apps.proje.liste.stateLoading')
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row class="demo-ruleForm animated fadeInUp card-base">
        <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <div style="" class="sozlesmeSelect" v-if="sozlesmeList.length > 0">
            <el-row>
              <el-col
                :lg="12"
                :md="24"
                :sm="24"
                style="
                  display: flex;
                  justify-content: start;
                  margin: 10px 0;
                  padding-right: 10px;
                "
              >
                <div class="display-end">
                  <ul class="islemler">
                    <li>
                      <i
                        class="mdi mdi-circle mdi-18px"
                        style="color: rgb(202 235 207)"
                      ></i>
                      <span>Aktif İşler</span>
                    </li>
                    <li>
                      <i
                        class="mdi mdi-circle mdi-18px"
                        style="color: rgb(246 223 223)"
                      ></i>
                      <span>Geciken İşler</span>
                    </li>
                  </ul>
                </div>
              </el-col>
              <el-col
                :lg="12"
                :md="24"
                :sm="24"
                :xs="24"
                style="
                  display: flex;
                  justify-content: end;
                  margin: 10px 0;
                  padding-right: 10px;
                "
              >
                <div class="display-end">
                  <ul class="islemler">
                    <li>
                      <i
                        class="mdi mdi-circle-edit-outline mdi-18px"
                        style="color: rgb(247, 186, 42)"
                      ></i>
                      <span>Tarih Güncelle</span>
                    </li>
                    <li>
                      <i
                        class="mdi mdi-account mdi-18px"
                        style="color: rgb(23, 177, 23)"
                      ></i>
                      <span>Personel Güncelle</span>
                    </li>
                    <li>
                      <i
                        class="mdi mdi-pause mdi-18px mr-10"
                        style="color: #006cae"
                      ></i>
                      <span>Sonlandır</span>
                    </li>
                    <li>
                      <i
                        class="mdi mdi-pause mdi-18px"
                        style="color: #ec2020"
                      ></i>
                      <span>Gecikmeli Sonlandır</span>
                    </li>
                  </ul>
                </div>
              </el-col>
            </el-row>
            <el-row style="padding: 10px">
              <!-- #F93862 -->
              <el-col :lg="10" style="padding: 0 20px">
                <template> Proje Süreç İlerleme Durumu: </template>
                <!--                11111111111111111111111111111111  {{projeForm.sozlesmeTamamlanmaYuzde}}-->
                <el-progress
                  :stroke-width="24"
                  :status="
                    projeForm.sozlesmeTamamlanmaYuzde == '100'
                      ? 'success'
                      : projeForm.sozlesmeTamamlanmaYuzde > 100
                      ? 'exception'
                      : null
                  "
                  :color="
                    projeForm.sozlesmeTamamlanmaYuzde == '100'
                      ? 'rgba(33, 219, 62, 0.75)'
                      : projeForm.sozlesmeTamamlanmaYuzde > 100
                      ? 'rgb(255 0 0)'
                      : 'rgba(33, 219, 62, 0.75)'
                  "
                  :percentage="Math.round(projeForm.sozlesmeTamamlanmaYuzde)"
                ></el-progress>
              </el-col>
              <el-col :lg="10" style="padding: 0 20px">
                <div style="display: flex; justify-content: space-around">
                  <div>
                    Başlama Tarihi:
                    <b>{{ formatDate(projeForm.sozlesmeBaslamaTarihi) }}</b>
                  </div>
                  <div>
                    Bitiş Tarihi:
                    <b>{{ formatDate(projeForm.sozlesmeBitisTarihi) }}</b>
                  </div>
                </div>
                <el-progress
                  :stroke-width="24"
                  :status="
                    projeForm.yuzdeDeger == '100'
                      ? 'success'
                      : projeForm.yuzdeDeger > 100
                      ? 'exception'
                      : null
                  "
                  :color="
                    projeForm.yuzdeDeger == '100'
                      ? 'rgba(33, 219, 62, 0.75)'
                      : projeForm.yuzdeDeger > 100
                      ? 'rgb(255 0 0)'
                      : 'rgba(33, 219, 62, 0.75)'
                  "
                  :percentage="
                    projeForm.yuzdeDeger < 0
                      ? 0
                      : Math.round(projeForm.yuzdeDeger)
                  "
                ></el-progress>
              </el-col>
              <el-col :lg="1" v-if="isDesktop">
                <div class="cizgi"></div>
              </el-col>
              <el-col :lg="3" style="padding: 3px 20px 0 20px">
                <el-row>
                  <el-col :xs="8" :sm="6" :lg="16">Toplam Gün:</el-col>
                  <el-col :xs="4" :sm="6" :lg="8"
                    ><b>{{ projeForm.toplamGun }}</b></el-col
                  >
                  <el-col :xs="8" :sm="6" :lg="16">Kalan Gün:</el-col>
                  <el-col :xs="4" :sm="6" :lg="8"
                    ><b>{{ projeForm.kalanGun }}</b></el-col
                  >
                </el-row>
              </el-col>
            </el-row>
            <el-table
              class="emrahTablo"
              :row-class-name="tableRowClassName"
              :data="sozlesmeList"
              border
              height="520"
              style="overflow-y: auto"
            >
              <el-table-column
                class="sabit-baslik"
                :fixed="isDesktop ? 'left' : null"
                prop="sozlesmeAdi"
                width="300"
              >
                <template slot="header">
                  <div class="pl-5">Sözleşmeler</div>
                </template>
                <template slot-scope="prop">
                  <div class="pl-5" style="overflow: hidden">
                    {{ prop.row.sozlesmeBaslik }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Personel"
                width="auto"
                :fixed="isDesktop ? 'left' : null"
                prop="adSoyad"
                align="center"
              >
                <template slot-scope="prop">
                  <div
                    @dblclick="updatePerson(prop.row.projeAtamaID)"
                    style="
                      width: 100%;
                      height: 100%;
                      display: list-item;
                      cursor: pointer;
                    "
                  >
                    {{ prop.row.adSoyad }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="isSuresi"
                width="auto"
                :fixed="isDesktop ? 'left' : null"
                align="center"
              >
                <template slot="header">
                  <div class="pl-5">Başlangıç Tarihi</div>
                </template>
                <template slot-scope="prop">
                  <div class="pl-5">
                    {{ formatDate(prop.row.baslamaTarihi) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="gecikenGun"
                width="auto"
                :fixed="isDesktop ? 'left' : null"
                align="center"
              >
                <template slot="header">
                  <div class="pl-5">Bitmesi Gereken Tarih</div>
                </template>
                <template slot-scope="prop">
                  <div class="pl-5">
                    {{ formatDate(prop.row.tamamlanmaTarihi) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="gecikenGun"
                width="auto"
                :fixed="isDesktop ? 'left' : null"
                align="center"
              >
                <template slot="header">
                  <div class="pl-5">Kapanma Tarihi</div>
                </template>
                <template slot-scope="prop">
                  <div v-if="prop.row.isTamamlanmaTarihi" class="pl-5">
                    {{ formatDate(prop.row.isTamamlanmaTarihi) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="gecikenGun"
                width="auto"
                :fixed="isDesktop ? 'left' : null"
                align="center"
              >
                <template slot="header">
                  <div class="pl-5">Geciken Gün</div>
                </template>
                <template slot-scope="prop">
                  <el-tooltip
                    :open-delay="300"
                    class="item"
                    effect="dark"
                    :content="
                      prop.row.aciklama && prop.row.aciklama.length > 20
                        ? prop.row.aciklama.substring(0, 20) + '...'
                        : prop.row.aciklama
                        ? prop.row.aciklama
                        : 'Gösterilecek veri yok'
                    "
                    placement="left"
                  >
                    <div
                      @click="open(prop.row)"
                      style="
                        cursor: pointer;
                        background-color: red;
                        color: white;
                      "
                    >
                      {{
                        getClosingDelay(
                          prop.row.tamamlanmaTarihi,
                          prop.row.isTamamlanmaTarihi
                        )
                      }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <!-- <el-table-column v-for="(item, index) in tarihler2" :key="index" :prop="item.prop"
                          align="center" width="90">
                          <template slot="header">
                              <div class="new-line-text" :style="{}">
                                  <div style="">
                                      {{ item.month }}</div>

                              </div>
                          </template>

                          <template slot-scope="prop">
                              <div style="width: 100%; height: 100%;">
                                  <div :style="dateStyle(prop.row, item)">

                                  </div>
                                  <div style="position: relative; z-index: 2;">
                                      {{ prop.row.sozlesmeAylari.find(e => e.monthIndex == item.monthIndex) ?
                                          prop.row.sozlesmeAylari.find(e => e.monthIndex == item.monthIndex).days
                                          : '' }}
                                  </div>
                              </div>
                          </template>

                      </el-table-column> -->
              <el-table-column
                prop="gecikenGun"
                width="220"
                :fixed="isDesktop ? 'left' : null"
                align="center"
              >
                <template slot="header">
                  <div class="pl-5">Değerlendirme</div>
                </template>
                <template slot-scope="prop">
                  <div
                    class="pl-5"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <el-rate
                      :value="prop.row.yildiz"
                      @change="
                        rateChanged(
                          prop.row.isDurumu,
                          prop.row.musteriID,
                          prop.row.projeID,
                          prop.row.sozlesmeID,
                          prop.row.yoneticiID,
                          prop.row.degerlendirmeAciklamasi,
                          $event
                        )
                      "
                    ></el-rate>
                    <el-tooltip
                      v-if="
                        prop.row.degerlendirmeAciklamasi !== null &&
                        prop.row.degerlendirmeAciklamasi.length > 1
                      "
                      :content="
                        prop.row.degerlendirmeAciklamasi &&
                        prop.row.degerlendirmeAciklamasi.length > 20
                          ? prop.row.degerlendirmeAciklamasi.substring(0, 20) +
                            '...'
                          : prop.row.degerlendirmeAciklamasi
                          ? prop.row.degerlendirmeAciklamasi
                          : ''
                      "
                      :open-delay="500"
                      placement="left"
                    >
                      <span
                        style="
                          color: #006cae;
                          font-size: 20px !important;
                          font-weight: 600;
                          position: absolute;
                          right: 10px;
                          top: 1px;
                        "
                      >
                        <i class="mdi mdi-alert mdi-24px"></i>
                      </span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="gecikenGun"
                width="200"
                :fixed="isDesktop ? 'left' : null"
              >
                <template slot="header">
                  <div class="pl-5" align="center">İşlemler</div>
                </template>
                <template slot-scope="prop">
                  <div
                    class="pl-5 butonKapsayan"
                    style="display: flex; align-items: center"
                  >
                    <el-tooltip
                      :content="'Güncelle'"
                      :open-delay="500"
                      placement="bottom"
                    >
                      <el-button
                        style="color: rgb(247, 186, 42)"
                        class="buttonDel"
                        @click="updateList(prop.row.projeAtamaID)"
                        type="text"
                        icon="mdi mdi-circle-edit-outline mdi-24px"
                      >
                      </el-button>
                    </el-tooltip>
                    <el-tooltip
                      :content="'Personel Güncelle'"
                      :open-delay="500"
                      placement="bottom"
                    >
                      <el-button
                        style="
                          margin-left: 15px !important;
                          color: rgb(23 177 23);
                        "
                        class=""
                        @click="updatePerson(prop.row.projeAtamaID)"
                        type="text"
                        icon="mdi mdi-account mdi-24px"
                      >
                      </el-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="
                        !isBitirmeTarihiGecmis(prop.row.tamamlanmaTarihi) &&
                        prop.row.isDurumu == 10
                      "
                      :content="'Sonlandır'"
                      :open-delay="500"
                      placement="bottom"
                    >
                      <el-button
                        style="margin-left: 15px !important"
                        class=""
                        @click="
                          changeisDurum(
                            prop.row.gorevAtamaID,
                            '20',
                            prop.row.tamamlanmaTarihi
                          )
                        "
                        type="text"
                        icon="mdi mdi-pause mdi-24px"
                      >
                      </el-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="
                        isBitirmeTarihiGecmis(prop.row.tamamlanmaTarihi) &&
                        prop.row.isDurumu == 10
                      "
                      :content="'Gecikmeli Sonlandır'"
                      :open-delay="500"
                      placement="bottom"
                    >
                      <el-button
                        style="
                          margin-left: 15px !important;
                          color: #ec2020 !important;
                        "
                        @click="
                          changeisDurum(
                            prop.row.gorevAtamaID,
                            '30',
                            prop.row.tamamlanmaTarihi
                          )
                        "
                        type="text"
                        icon="mdi mdi-pause mdi-24px"
                      >
                      </el-button>
                    </el-tooltip>

                    <!-- <el-button class="mt-5 kptBtn"
                                v-if="prop.row.gecikenGun == null && prop.row.isDurumu == 10" size="mini"
                                plain type="primary" icon="mdi mdi-pause mdi-24px"
                                @click="changeisDurum(prop.row.gorevAtamaID, '20', prop.row.tamamlanmaTarihi)">

                            </el-button>
                            <el-button class="" v-if="prop.row.gecikenGun > 0 && prop.row.isDurumu == 10"
                                size="mini" type="danger" plain icon="mdi mdi-pause"
                                @click="changeisDurum(prop.row.gorevAtamaID, '30', prop.row.tamamlanmaTarihi)">

                            </el-button> -->
                    <!-- <el-button plain size="mini" icon="el-icon-edit" type="warning">
                                <label>Güncelle</label>
                            </el-button> -->
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="Tarih Güncelle"
      :visible.sync="dialogDate"
      width="30%"
      top="15%"
    >
      <el-form :model="tarihForm">
        <el-form-item label="Başlama Tarihi">
          <el-date-picker
            :picker-options="datePickerOptions"
            format="dd-MM-yyyy"
            v-model="tarihForm.baslamaTarihi"
            type="date"
            placeholder="Başlama Tarihi"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="Tamamlanma Tarihi">
          <el-date-picker
            :picker-options="datePickerOptions"
            format="dd-MM-yyyy"
            v-model="tarihForm.tamamlanmaTarihi"
            type="date"
            placeholder="Tamamlanma Tarihi"
            style="width: 100%"
          />
        </el-form-item>
        <!-- ... Diğer form alanları ... -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDate = false">İptal</el-button>
        <el-button type="primary" @click="sozlesmeGuncelle">Güncelle</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Personel Güncelle"
      :visible.sync="dialogPerson"
      width="30%"
      top="15%"
    >
      <el-form :model="personForm">
        <el-form-item label="Personel">
          <el-select
            v-model="personForm.selectedPersonel"
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="person in personForm.personel"
              :key="person.yoneticiID"
              :label="person.adSoyad"
              :value="person.yoneticiID"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- ... Diğer form alanları ... -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPerson = false">İptal</el-button>
        <el-button type="primary" @click="personGuncelle">Güncelle</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="gecikenForm.personel"
      :visible.sync="dialogGeciken"
      width="30%"
      top="15%"
    >
      <el-row class="mb-5">
        <el-col :lg="8"><b>Müşteri:</b></el-col>
        <el-col :lg="12">{{ gecikenForm.musteri }}</el-col>
      </el-row>
      <el-row class="mb-5">
        <el-col :lg="8"><b>Proje:</b></el-col>
        <el-col :lg="12">{{ gecikenForm.proje }}</el-col>
      </el-row>
      <el-row class="mb-5">
        <el-col :lg="8"><b>Başlama Tarihi:</b></el-col>
        <el-col :lg="12">{{ gecikenForm.baslamaTarihi }}</el-col>
      </el-row>
      <el-row class="mb-5">
        <el-col :lg="8"><b>Bitiş Tarihi:</b></el-col>
        <el-col :lg="12">{{ gecikenForm.bitisTarihi }}</el-col>
      </el-row>
      <el-row class="mb-5">
        <el-col :lg="8"><b>Tamamlanma Tarihi:</b></el-col>
        <el-col :lg="12">{{ gecikenForm.tamamlanmaTarihi }}</el-col>
      </el-row>
      <el-row class="mb-5">
        <el-col :lg="8"><b>Gecikme:</b></el-col>
        <el-col :lg="12">{{ gecikenForm.gecikme }} gün</el-col>
      </el-row>
      <el-row class="mb-5">
        <el-col :lg="8"><b>Açıklama:</b></el-col>
        <el-col :lg="12">{{ gecikenForm.aciklama }}</el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogGeciken = false"
          >Tamam</el-button
        >
      </span>
    </el-dialog>
    <gecikme-dialog
      v-if="dialogVisibleYonetici"
      :dialogvisible.sync="dialogVisibleYonetici"
      :userdata="userDataBGSurec"
    ></gecikme-dialog>
  </div>
</template>

<script>
import ClassicEditor from "../../../../node_modules/ckeditor5-build-classic";
import draggable from "vuedraggable";

import haberService from "../../../WSProvider/HaberService";
import personelService from "@/WSProvider/PersonelService";
import musteriService from "../../../WSProvider/MusteriService";
import projeService from "../../../WSProvider/ProjeService";
import kapsamService from "../../../WSProvider/KapsamService";
import notification from "../../../notification";
import functions from "../../../functions";
import EventBus from "@/components/event-bus";
import { json } from "body-parser";
import GecikmeDialog from "@/components/GecikmeDialog";

var moment = require("moment");
let time = moment().format("YYYY-MM-DD");

export default {
  name: "kapsamSozlesmeListesi",
  components: {
    GecikmeDialog,
  },
  watch: {
    sozlesmeKey(val) {
      this.seciliSozlesmeFiltre();
      this.seciliSozlesmeler.length > 0
        ? (this.sozlesmeTarihBaslikGoster = true)
        : (this.sozlesmeTarihBaslikGoster = false);
    },

    storeData: {
      handler(val) {
        this.projeForm.projeID = val;
        this.getSozlesmeListesi();
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    storeData() {
      return this.$store.state.projeUpdateData;
    },

    tarihler() {
      const uniqueMonths = new Set();
      this.sozlesmeList.forEach((sozlesme) => {
        const baslamaDate = new Date(sozlesme.baslamaTarihi);
        const bitisDate = new Date(sozlesme.tamamlanmaTarihi);

        const currentYear = baslamaDate.getFullYear();
        let currentMonth = baslamaDate.getMonth();

        while (
          currentYear < bitisDate.getFullYear() ||
          (currentYear === bitisDate.getFullYear() &&
            currentMonth <= bitisDate.getMonth())
        ) {
          uniqueMonths.add(`${currentYear}-${currentMonth}`);

          if (currentMonth === 11) {
            currentYear++;
            currentMonth = 0;
          } else {
            currentMonth++;
          }
        }
      });

      const tarihler = [...uniqueMonths].map((monthYear, index) => {
        const [year, month] = monthYear.split("-");
        const monthIndex = Number(month);

        return {
          month: this.getMonthName(monthIndex),
          prop: `ay_${index}`,
          monthIndex: monthIndex,
        };
      });

      return tarihler;
    },
  },
  created() {
    this.checkDevice();
  },
  mounted() {
    window.addEventListener("resize", this.checkDevice);
    this.projeForm.projeID = this.$store.getters.getProjeDetayData
      ? this.$store.getters.getProjeDetayData
      : this.$store.getters.getProjeUpdateData;
    this.getSozlesmeListesi();
    // this.projeForm.ulkeID = this.$store.getters.getILData
    // this.getIlListesi();
    this.sozlesmeList.forEach((el) => {
      el.secili = false;
      el.baslamaTarihi = "";
      el.isTeslimTarihi = "";
    });
    this.sozlesmeKey++;
  },
  beforeDestroy() {
    this.$store.commit("changeMusteriDetayData", "");
    this.$store.commit("changeProjeDetayData", "");
    window.removeEventListener("resize", this.checkDevice);
  },
  data() {
    return {
      stateLoading: false,
      dialogDate: false,
      dialogPerson: false,
      dialogGeciken: false,
      dialogVisibleYonetici: false,
      puan: null,
      isDesktop: false,
      denemekey: 0,
      denemekey2: 0,
      projeData: {},
      musteriler: [],
      ulkeler: [],
      iller: [],
      ilceler: [],
      tarihler2: [],
      sozlesmeList: [],
      gruplanmisSozlesmeler: [],
      datePickerOptions: {
        firstDayOfWeek: 1, // Pazartesi (0:Pazar, 1:Pazartesi, ..., 6:Cumartesi)
      },
      tumunuSec: false,
      sozlesmeKey: 0,
      loading: false,
      selectedImagesFromServer: [],
      path: haberService.path,
      imagePath: haberService.imagePath,
      belgePath: haberService.belgePath,
      editor: ClassicEditor,
      editorConfig: {
        language: "tr",
      },
      activeCollapse: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
      visibleSil: false,
      disabled: false,
      options: [],
      dateRangeOptions: {
        firstDayOfWeek: 1,
      },
      seciliSozlesmeler: [],
      sozlesmeTarihBaslikGoster: false,
      projeForm: {
        kategori: "",
        musteriAdi: "",
        projeBaslik: "",
        alan: "",
        musteriID: "",
        ulkeID: "",
        ulkeAdi: "",
        ilID: "",
        ilBaslik: "",
        ilceID: "",
        ilceBaslik: "",
        adresBaslik: "",
        baslamaTarihi: time,
        tamamlanmaTarihi: time,
        sozlesmeBaslamaTarihi: "",
        sozlesmeBitisTarihi: "",
        toplamGun: "",
        kalanGun: "",
        sozlesmeYuzde: "",
        sozlesmeTamamlanmaYuzde: "",
        durum: "1",
        sozlesmeler: [],
        sozlesmeAtamalari: [],
      },
      tarihForm: {
        baslamaTarihi: "",
        tamamlanmaTarihi: "",
      },
      personForm: {
        selectedPersonel: null,
        personel: [],
      },
      gecikenForm: {
        personel: "",
        musteri: "",
        proje: "",
        baslamaTarihi: "",
        bitisTarihi: "",
        tamamlanmaTarihi: "",
        gecikme: "",
        aciklama: "",
      },
      selectIcon: "1",
      page: 0,
      postPage: 0,
      sizes: 10,
      rulesProje: {
        projeBaslik: [
          {
            required: true,
            message: "Lütfen Proje Adı Giriniz",
            trigger: "blur",
          },
        ],
        musteriAdi: [
          {
            required: true,
            message: "Lütfen Firma Seçiniz",
            trigger: "blur",
          },
        ],
        alan: [
          {
            required: true,
            message: "Lütfen Alan Giriniz",
            trigger: "blur",
          },
        ],
        eposta: [
          {
            required: true,
            message: "Lütfen E-posta Giriniz",
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("src.views.apps.messages.enterValidMail"),
            trigger: "blur",
          },
        ],
        adresBaslik: [
          {
            required: true,
            message: "Lütfen Adres Giriniz",
            trigger: "blur",
          },
        ],
        ulkeID: [
          {
            required: true,
            message: "Lütfen Ülke Seçiniz",
            trigger: "blur",
          },
        ],
        ilID: [
          {
            required: true,
            message: "Lütfen İl Seçiniz",
            trigger: "blur",
          },
        ],
        ilceID: [
          {
            required: true,
            message: "Lütfen İlçe Seçiniz",
            trigger: "blur",
          },
        ],
        baslamaTarihi: [
          {
            required: true,
            message: "Lütfen Tarih Seçiniz",
            trigger: "blur",
          },
        ],
        tamamlanmaTarihi: [
          {
            required: true,
            message: "Lütfen Tarih Seçiniz",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    open(sozlesme) {
      const musteriAdi = this.projeForm.musteriAdi;
      const projeAdi = this.projeForm.projeBaslik;
      this.userDataBGSurec = {
        sozlesme: sozlesme,
        musteriAdi: musteriAdi,
        projeAdi: projeAdi,
      };
      this.dialogVisibleYonetici = true;
    },
    isBitirmeTarihiGecmis(tarih) {
      const bugun = new Date();
      const tarihObjesi = new Date(tarih);
      return tarihObjesi < bugun;
    },
    gecikenEvent(data) {
      this.gecikenForm.musteri = data.musteriAdi;
      this.gecikenForm.personel = data.adSoyad;
      this.gecikenForm.proje = this.projeForm.projeBaslik;
      this.gecikenForm.baslamaTarihi = data.baslamaTarihi;
      this.gecikenForm.bitisTarihi = data.isTeslimTarihi;
      this.gecikenForm.tamamlanmaTarihi = data.isTamamlanmaTarihi;
      this.gecikenForm.gecikme = this.getClosingDelay(
        data.tamamlanmaTarihi,
        data.isTamamlanmaTarihi
      );
      this.gecikenForm.aciklama = data.aciklama;

      this.dialogGeciken = true;
    },
    getClosingDelay(completionDate, closingDate) {
      if (!completionDate || !closingDate) return ""; // Handle if the dates are missing

      const completionTime = new Date(completionDate).getTime();
      const closingTime = new Date(closingDate).getTime();

      const timeDifference = closingTime - completionTime;
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      if (daysDifference > 0) {
        return `${daysDifference}`;
      }
    },
    rateChanged(
      isDurumu,
      musteriID,
      projeID,
      sozlesmeID,
      yoneticiID,
      deger,
      yildiz
    ) {
      if (isDurumu == 10 || isDurumu == null) {
        notification.Status(
          "warning",
          this,
          "Kapatılmamış iş için değerlendirme yapamazsınız"
        );
      } else {
        try {
          this.$prompt(
            "Personeli değerlendirmek için açıklama girmek ister misiniz?",
            "Değerlendirme",
            {
              confirmButtonText: "Kaydet",
              showCancelButton: false,
              inputValue: deger,
            }
          )
            .then(({ value }) => {
              kapsamService
                .performansdegerlendir(
                  musteriID,
                  projeID,
                  sozlesmeID,
                  yoneticiID,
                  yildiz,
                  value
                )
                .then((response) => {
                  localStorage.setItem("userDataBGSurec", response.token);
                  if (response.status == 200) {
                    notification.Status("success", this, response.msg);
                    this.getSozlesmeListesi();
                  }
                })
                .catch((err) => {
                  if (err.responseJSON) {
                    let error = err.responseJSON;
                    if (error.status == 401) {
                      notification.Status(150, this);
                    } else if (error.status == 404) {
                    } else notification.Status("errorMsg", this, error.errMsg);
                  } else {
                  }
                });
            })
            .catch(() => {
              console.log("İşlem iptal edildi.");
              kapsamService
                .performansdegerlendir(
                  musteriID,
                  projeID,
                  sozlesmeID,
                  yoneticiID,
                  yildiz,
                  ""
                )
                .then((response) => {
                  localStorage.setItem("userDataBGSurec", response.token);
                  if (response.status == 200) {
                    notification.Status("success", this, response.msg);
                    this.getSozlesmeListesi();
                  }
                })
                .catch((err) => {
                  if (err.responseJSON) {
                    let error = err.responseJSON;
                    if (error.status == 401) {
                      notification.Status(150, this);
                    } else if (error.status == 404) {
                      // ...
                    } else notification.Status("errorMsg", this, error.errMsg);
                  } else {
                    // ...
                  }
                });
            });
        } catch (e) {
          // ...
        }
      }
    },
    tableRowClassName({ row, rowIndex }) {
      const completionTime = new Date(row.tamamlanmaTarihi).getTime();
      const closingTime = new Date().getTime();
      const timeDifference = closingTime - completionTime;
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      let tarih = new Date().toISOString().slice(0, 19).replace("T", " ");
      let bitmesiGerekenTarih = row.tamamlanmaTarihi;
      if (daysDifference > 0 && row.isDurumu == 10) {
        return "danger-row";
      } else if (row.isDurumu == 10) {
        return "success-row";
      }
      return "";
    },
    sozlesmeGuncelle() {
      try {
        this.loading = true;
        const baslamaTarihi = new Date(this.tarihForm.baslamaTarihi);
        const tamamlanmaTarihi = new Date(this.tarihForm.tamamlanmaTarihi);

        const baslamaTarihiFormatted =
          baslamaTarihi.getFullYear() +
          "-" +
          (baslamaTarihi.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          baslamaTarihi.getDate().toString().padStart(2, "0");
        const tamamlanmaTarihiFormatted =
          tamamlanmaTarihi.getFullYear() +
          "-" +
          (tamamlanmaTarihi.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          tamamlanmaTarihi.getDate().toString().padStart(2, "0");

        kapsamService
          .tarihGuncelle(
            this.projeAtamaId,
            baslamaTarihiFormatted,
            tamamlanmaTarihiFormatted
          )
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.dialogDate = false; // Diyaloğu kapat
              this.getSozlesmeListesi();
              notification.Status("success", this, response.msg);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    personGuncelle() {
      try {
        this.loading = true;

        kapsamService
          .personGuncelle(this.projeAtamaId, this.personForm.selectedPersonel)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.dialogPerson = false; // Diyaloğu kapat
              this.getSozlesmeListesi();
              notification.Status("success", this, response.msg);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    changeisDurum(selection, durum, tamamlanmaTarihi) {
      const tarih = new Date().toISOString().slice(0, 19).replace("T", " ");

      const completionTime = new Date(tamamlanmaTarihi).getTime();
      const closingTime = new Date(tarih).getTime();
      const timeDifference = closingTime - completionTime;
      const gecikenTarih = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      const promptOptions = {
        confirmButtonText: this.$t("src.views.apps.genel.yes"),
        cancelButtonText: this.$t("src.views.apps.genel.no"),
      };

      if (durum == 30) {
        this.$prompt(
          "İş Gecikmeye Uğradı!!!",
          this.$t("src.views.apps.genel.uyari"),
          {
            ...promptOptions,
            confirmButtonText: "Sorun bildir ve işi sonlandır",
            showCancelButton: false,
            inputType: "textarea",
            inputPattern: /^.{1,300}$/,
            inputErrorMessage:
              "Lütfen 1 ila 300 karakter arasında bir mesaj girin.",
            inputPlaceholder: "Sebep",
          }
        )
          .then(({ value }) =>
            this.handleConfirmation(
              selection,
              durum,
              value,
              tarih,
              gecikenTarih
            )
          )
          .catch(() => {});
      } else {
        this.$confirm(
          "İşi sonlandırmak istediğinizden emin misiniz?",
          this.$t("src.views.apps.genel.uyari"),
          {
            ...promptOptions,
            type: "warning",
          }
        )
          .then(() => this.handleConfirmation(selection, durum, "", tarih, 0))
          .catch(() => {});
      }
    },
    updateList(projeAtamaId) {
      try {
        this.projeAtamaId = projeAtamaId;
        kapsamService
          .sozlesmeTarihGetir(projeAtamaId)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.tarihForm.baslamaTarihi = response.data.baslamaTarihi;
              this.tarihForm.tamamlanmaTarihi = response.data.tamamlanmaTarihi;
              this.dialogDate = true;
            }
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    updatePerson(projeAtamaId) {
      try {
        this.projeAtamaId = projeAtamaId;
        kapsamService
          .sozlesmePersonelGetir(projeAtamaId)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.personForm.personel = response.data.personelList;
              this.personForm.selectedPersonel = response.data.atanmisPersonel
                ? response.data.atanmisPersonel.yoneticiID
                : null;
              this.dialogPerson = true;
              console.log(this.personForm.selectedPersonel);
            }
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    handleConfirmation(selection, durum, mesaj, tarih, gecikenTarih) {
      try {
        this.stateLoading = true;
        var item = durum;
        personelService
          .gorevGuncelle(selection, item, mesaj, tarih, gecikenTarih)
          .then((response) => {
            if (response.status === 200) {
              this.getSozlesmeListesi();
            }
            this.stateLoading = false;
            notification.Status("success", this, response.msg);
            localStorage.setItem("userDataBGSurec", response.token);
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status === 401) {
                notification.Status(150, this);
              } else {
                notification.Status("errorMsg", this, error.errMsg);
              }
            } else {
            }
            this.stateLoading = false;
          });
      } catch (e) {
        this.stateLoading = false;
      }
    },
    formatDate(inputDate) {
      const date = new Date(inputDate);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay, 0 ile başlar
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },
    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;
      // this.getMusteriSayisi();
      //this.getMusteriListesi();
      //this.getUlkeListesi();
      this.getSozlesmeListesi();
      // this.getIlListesi();
    },

    getSozlesmeListesi() {
      try {
        this.loading = true;
        kapsamService
          .sozlesmeListesi(this.projeForm.projeID)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.sozlesmeList = response.data.projeDetay;
              this.total = parseInt(response.count);

              const yuzdeDegerToplami = this.sozlesmeList
                .filter(
                  (sozlesme) =>
                    sozlesme.isDurumu == 20 || sozlesme.isDurumu == 30
                )
                .reduce(
                  (toplam, sozlesme) =>
                    toplam + parseFloat(sozlesme.yuzdeDeger),
                  0
                );

              const yuzdeDegerTumToplam = this.sozlesmeList.reduce(
                (toplam, sozlesme) => toplam + parseFloat(sozlesme.yuzdeDeger),
                0
              );

              // this.projeForm.sozlesmeTamamlanmaYuzde = yuzdeDegerToplami.toFixed(1); // Toplamı 2 ondalık basamağa yuvarla ve atama yap

              console.log("yuzdeDegerToplami", yuzdeDegerToplami);

              this.projeForm.sozlesmeTamamlanmaYuzde = parseFloat(
                (yuzdeDegerToplami * 100) / yuzdeDegerTumToplam
              ).toFixed(1);

              this.projeForm.musteriID = response.proje.musteriID;
              this.projeForm.musteriAdi = response.proje.musteriAdi;
              this.projeForm.projeBaslik = response.proje.projeBaslik;
              this.projeForm.alan = response.proje.alan;
              this.projeForm.ulkeID = response.proje.ulkeID;
              this.projeForm.ilID = response.proje.ilID;
              this.projeForm.ilceID = response.proje.ilceID;
              this.projeForm.adresBaslik = response.proje.adresBaslik;
              this.projeForm.baslamaTarihi = response.proje.baslangicTarihi;
              this.projeForm.tamamlanmaTarihi = response.proje.bitisTarihi;
              this.projeForm.sozlesmeBaslamaTarihi =
                response.proje.sozlesmeTotalBaslangic;
              this.projeForm.sozlesmeBitisTarihi =
                response.proje.sozlesmeTotalBitis;

              const baslamaTarihi = new Date(
                this.projeForm.sozlesmeBaslamaTarihi
              );
              const bitisTarihi = new Date(this.projeForm.sozlesmeBitisTarihi);
              const simdikiTarih = new Date();
              const oneDay = 24 * 60 * 60 * 1000; // Bir günün milisaniye cinsinden değeri

              const toplamGun = Math.round(
                Math.abs((bitisTarihi - baslamaTarihi) / oneDay)
              );
              this.projeForm.toplamGun = toplamGun;
              if (baslamaTarihi > simdikiTarih || bitisTarihi < simdikiTarih) {
                this.projeForm.kalanGun = 0;
              } else {
                const kalanGun = Math.round(
                  Math.abs((bitisTarihi - simdikiTarih) / oneDay)
                );
                this.projeForm.kalanGun = kalanGun;
              }

              const toplamSure = bitisTarihi - baslamaTarihi;
              const gecenSure = simdikiTarih - baslamaTarihi;

              const yuzdeDeger = (gecenSure / toplamSure) * 100;
              this.projeForm.yuzdeDeger = yuzdeDeger.toFixed(1); // Yüzdeyi iki
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    farkBul(object1, object2) {
      const result = {};
      for (const key in object1) {
        if (object1.hasOwnProperty(key) && object2.hasOwnProperty(key)) {
          if (key === "sozlesmeler" || key === "sozlesmeAtamalari") {
            continue; // sozlesmeler veya sozlesmeAtamalari anahtarları için fark kontrolü yapma, bir sonraki iterasyona geç
          }
          if (object1[key] !== object2[key]) {
            result[key] = object2[key];
          }
        }
      }
      return result;
    },
    indexMethod(index) {
      return (this.page - 1) * 10 + index + 1;
    },
    handleSizeChange(val) {
      this.sizes = val;
      this.getMusteriListesi();
      this.getUlkeListesi();
      this.getSozlesmeListesi();
    },
    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getMusteriListesi();
      this.getUlkeListesi();
      this.getSozlesmeListesi();
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getMusteriListesi();
      this.getUlkeListesi();
      this.getSozlesmeListesi();
    },
    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },
    sozlesmeSec(item) {
      let total = parseInt(item.baslangicSuresi) + parseInt(item.isSuresi);
      let baslama = moment(this.projeForm.baslamaTarihi).add(
        item.baslangicSuresi,
        "days"
      );
      let bitis = moment(this.projeForm.baslamaTarihi).add(total, "days");

      item.baslamaTarihi = moment(baslama).format("YYYY-MM-DD");
      item.isTeslimTarihi = moment(bitis).format("YYYY-MM-DD");
    },
    tumunuSecChange(event) {
      //console.log("burası")
      //console.log(this.tumunuSec)
      this.tumunuSec = this.tumunuSec;
      this.sozlesmeList.forEach((el) => (el.secili = this.tumunuSec));
      this.seciliSozlesmeler = this.sozlesmeList.filter(
        (el) => el.secili == this.tumunuSec
      );
      //console.log(this.seciliSozlesmeler)
      this.sozlesmeTarihBaslikGoster = this.tumunuSec;
    },
    baslamaSuresiDegistir(event, item) {
      // console.log("baslama suresi degiş item : ", item)
      let secilenProjeTarihi = moment(this.projeForm.baslamaTarihi);
      let baslamaTarihi = moment(item.baslamaTarihi);
      var fark = Math.abs(baslamaTarihi.diff(secilenProjeTarihi, "days"));
      // console.log("baslama suresi fark : ", fark)
      item.baslangicSuresi = fark;
    },
    bitisSuresiDegistir(event, item) {
      //   console.log("bitis suresi degiş item : ", item)
      let baslamaTarihi = moment(item.baslamaTarihi);
      let isTeslimTarihi = moment(item.isTeslimTarihi);
      var fark = Math.abs(isTeslimTarihi.diff(baslamaTarihi, "days"));
      //console.log("bitis suresi fark : ", fark)
      item.isSuresi = fark;
    },
    seciliSozlesmeFiltre() {
      this.seciliSozlesmeler = [];
      this.seciliSozlesmeler = this.sozlesmeList.filter(
        (el) => el.secili == true
      );
      //   console.log(this.seciliSozlesmeler)
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
    },
    calculateColumnTotal(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Aylık Toplam Gün";
          return;
        }
        if (index === 1) return;
        if (index === 2) return;

        //console.log("data")
        //console.log(data)

        let totalDays = 0;
        data.forEach((item) => {
          const ay = item.sozlesmeAylari.find(
            (e) => e.monthIndex === index - 2
          );
          if (ay) {
            totalDays += ay.days;
          }
        });
        //console.log("totalDays")
        //console.log(totalDays)

        sums[index] = totalDays == 0 ? "" : `${totalDays}`;
      });

      return sums;
    },
    dateStyle(prop, date) {
      // console.log("test");
      // console.log(prop);
      // prop benım o an tabloya cizdirdiğim sözleşmem
      // date de headerda bulunan her bir tarıh
      if (prop.sozlesmeAylari.find((e) => e.monthIndex == date.monthIndex)) {
        let konum = "";

        if (prop.sozlesmeAylari.length == 1) konum = "left";
        // sozlesmenin tarih kapsamı tek bir ay için geçerli ise sola yaslanmalı
        else if (prop.sozlesmeAylari.length > 1) {
          var index = prop.sozlesmeAylari.findIndex(
            (e) => e.monthIndex == date.monthIndex
          ); // eger birden fazla ay içeriyorsa ilk ay sağ tarafa konumlanmalı diğerleri sol kalmalı
          if (index == 0) konum = "right";
          else konum = "left";
        }

        const backgroundColor =
          prop.bitti == 1
            ? "rgb(121,184,209)"
            : prop.bitti == 0
            ? "rgb(255, 231, 160)"
            : prop.bitti == 2
            ? "rgb(205, 233, 144)"
            : "rgb(220,223,228)"; // mavi kırmızı yeşil 2 bitti 1 devam ediyor 0 başlamadı  //mavi kırmızı yeşil 2 bitti 1 devam ediyor 0 başlamadı  98C7E5  f9e0e7 C2E797
        return {
          position: "absolute",
          left: konum == "left" ? 0 : "",
          right: konum == "right" ? 0 : "",
          top: 0,
          zIndex: 1,
          height: "100%",
          background: backgroundColor,
          display: prop.sozlesmeAylari.find(
            (e) => e.monthIndex == date.monthIndex
          )
            ? "flex"
            : "none",
          justifyContent: "center",
          width: prop.sozlesmeAylari.find(
            (e) => e.monthIndex == date.monthIndex
          )
            ? `${
                prop.sozlesmeAylari.find((e) => e.monthIndex == date.monthIndex)
                  .dayPercentage
              }% !important`
            : "0",
        };
      }
    },
    // getProjeDetay() {
    //     try {
    //         this.loading = true;
    //         projeService.projeDetay(this.projeForm.projeID).then(response => {
    //             if (response.status == 200) {
    //                 localStorage.setItem("userDataBGSurec", response.token);
    //                 this.projeForm.musteriID = response.data.musteriID
    //                 this.projeForm.musteriAdi = response.data.musteriAdi
    //                 this.projeForm.projeBaslik = response.data.projeBaslik
    //                 this.projeForm.alan = response.data.alan
    //                 this.projeForm.ulkeID = response.data.ulkeID
    //                 this.projeForm.ulkeAdi = response.data.ulkeAdi
    //                 this.projeForm.ilID = response.data.ilID
    //                 this.projeForm.ilBaslik = response.data.ilBaslik
    //                 this.projeForm.ilceID = response.data.ilceID;
    //                 this.projeForm.ilceBaslik = response.data.ilceBaslik;
    //                 this.projeForm.adresBaslik = response.data.adresBaslik
    //                 this.projeForm.baslamaTarihi = response.data.baslamaTarihi
    //                 this.projeForm.tamamlanmaTarihi = response.data.tamamlanmaTarihi
    //                 this.projeForm.sozlesmeler = response.data.sozlesmeler
    //                 this.projeForm.sozlesmeBaslamaTarihi = response.data.sozlesmeBaslama
    //                 this.projeForm.sozlesmeBitisTarihi = response.data.sozlesmeBitis
    //                 const baslamaTarihi = new Date(this.projeForm.sozlesmeBaslamaTarihi);
    //                 const bitisTarihi = new Date(this.projeForm.sozlesmeBitisTarihi);
    //                 const oneDay = 24 * 60 * 60 * 1000; // Bir günün milisaniye cinsinden değeri
    //                 const toplamGun = Math.round(Math.abs((bitisTarihi - baslamaTarihi) / oneDay));
    //                 this.projeForm.toplamGun = toplamGun;
    //                 const simdikiTarih = new Date();
    //                 const kalanGun = Math.round(Math.abs((bitisTarihi - simdikiTarih) / oneDay));
    //                 this.projeForm.kalanGun = kalanGun;
    //                 const toplamSure = bitisTarihi - baslamaTarihi;
    //                 const gecenSure = simdikiTarih - baslamaTarihi;
    //                 const yuzdeDeger = (gecenSure / toplamSure) * 100;
    //                 this.projeForm.yuzdeDeger = yuzdeDeger.toFixed(2); // Yüzdeyi iki
    //                 response.data.sozlesmeler.forEach(el => {
    //                     var sozlesme = this.sozlesmeList.find(e => el.sozlesmeID == e.sozlesmeID)
    //                     if (sozlesme) {
    //                         sozlesme.baslamaTarihi = el.baslamaTarihi
    //                         sozlesme.isTeslimTarihi = el.isTeslimTarihi
    //                         sozlesme.yuzdeDeger = el.yuzdeDeger
    //                         sozlesme.isSuresi = el.isSuresi
    //                         sozlesme.baslangicSuresi = el.baslangicSuresi
    //                         sozlesme.secili = true
    //                     }
    //                 })
    //                 /*
    //                 if(response.data.sozlesmeler.length == 47){
    //                     this.tumunuSec = true
    //                 }*/
    //                 this.sozlesmeKey++
    //                 this.projeData = response.data
    //             }
    //             this.loading = false;
    //         }).catch(err => {
    //             if (err.responseJSON) {
    //                 let error = err.responseJSON
    //                 if (error.status == 401) {
    //                     notification.Status(150, this);
    //                 }
    //                 else notification.Status("errorMsg", this, error.errMsg)
    //             } else {
    //                 //notification.Status(503, this)
    //             }
    //             this.loading = false;
    //         })
    //     } catch (e) {
    //         this.loading = false;
    //     }
    // },
  },
};

function getMonthsBetween(start, end) {
  moment.locale("tr");
  const results = [];

  for (
    let m = moment(start);
    m.isSameOrBefore(end, "month");
    m.add(1, "months")
  ) {
    //for (let m = moment(start); m.isBefore(end); m.add(1, 'months')) {
    const monthStart = moment(m).startOf("month");
    const monthEnd = moment(m).endOf("month");

    let daysInMonth = monthEnd.diff(monthStart, "days") + 1; //ayın günlerinikaç gün olduğunu hesaplıyor

    if (m.isSame(moment(start).startOf("month"), "month")) {
      daysInMonth -= moment(start).date();
    }
    if (m.isSame(moment(end).endOf("month"), "month")) {
      daysInMonth -= monthEnd.date() - moment(end).date();
    }

    results.push({
      month: monthStart.format("MMMM"),
      monthIndex: parseInt(monthStart.month()) + 1,
      days: daysInMonth, //- 1
    });
  }
  return results;
}

//bugunun tarihi sözleşmedeki baslangıc ve bitiş tarihi ile karsılastırılır. Eğer sozlesme başlamadıysa durum 0, baslamıs ise yani bulundugumuz günün tarihi baslangıc ve bitis tarihleri arasında ise durum 1, bitiş tarihini geçmiş ise durum 2 yani bitmiş olarak değerlendirilecek.
function checkDateRange(start, end) {
  const today = moment();
  if (today.isBefore(start, "day")) {
    return 0;
  } else if (today.isBetween(start, end, "day")) {
    return 1;
  } else {
    return 2;
  }
}

function getDays(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür ve gün farkını hesapla
  const tarih1Moment = moment(tarih1);
  const tarih2Moment = moment(tarih2);
  const gunFarki = tarih2Moment.diff(tarih1Moment, "days");

  // Sonucu döndür
  return gunFarki;
}

function sozlesmeBittiMi(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür
  const momentTarih1 = moment(tarih1);
  const momentTarih2 = moment(tarih2);

  // Tarih1 tarih2'den sonra mı?
  return momentTarih1.isAfter(momentTarih2);
}

function projeBasladiMi(bugun, baslamaTarihi) {
  const momentBugun = moment(bugun);
  const momentBaslangic = moment(baslamaTarihi);

  return momentBugun.isAfter(momentBaslangic);
}
</script>

<style lang="scss">
.sozlesmeSelect {
  .el-table__header thead th:nth-child(1) {
    background-color: #edfcff !important;
  }

  .el-table__header thead th:nth-child(2) {
    background-color: #edfcff !important;
  }

  .el-row:nth-child(2n) {
    background: #7ba7c1 !important;
  }

  .el-table .success-row {
    background: #caebcf;
  }

  .el-table .danger-row {
    background: #e399994f;
  }

  .emrahTablo .el-table__cell .cell {
    padding: 0 !important;
  }

  .emrahTablo .el-table__header-wrapper table th {
    background-color: #edfcff !important;
  }
}
</style>
<style scoped>
@media screen and (max-width: 480px) {
  .islemler {
    text-align: left !important;
  }
}

.sabit-baslik {
  position: sticky;
  top: 100px;
  z-index: 1;
  /* İhtiyaca bağlı olarak z-index ayarı yapabilirsiniz */
}

.cizgi {
  width: 2px;
  display: block;
  height: 44px;
  background-color: #006cae29;
  justify-content: center;
  margin: 0 15px;
}

.butonKapsayan button {
  padding: 0;
}

.aktifText {
  color: green;
}

.dangerText {
  color: red;
}

.warningText {
  color: orange;
}

.infoText {
  color: rgb(9, 63, 238);
}
</style>
